import React from "react";
import banner from "../banner/banner.png";
import './header.css'

function Header() {
  return (
    <nav>
      <img src={banner} alt="VAPED4U_BARGAINS" />
    </nav>
  );
}

export default Header;
