import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import SignUpForm from "./components/SignUpForm";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<SignUpForm />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
