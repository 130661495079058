import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {theme} from "./../theme";

const Footer = () => {
  const [shouldStick, setShouldStick] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const contentElement = document.body;
      const isContentScrollable =
        contentElement.scrollHeight > window.innerHeight;

      setShouldStick(!isContentScrollable);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        minHeight: "5vh",
        zIndex: 1,
        backgroundColor: theme.palette.primary.main,
        position: shouldStick ? "fixed" : "static",
        width: "100%",
        left: 0,
        bottom: 0,
        textAlign: "center",
        color: "white",
        "& a": {
          color: "#2c53a0",
        },
      }}
    >
      Developed by{" "}
      <a href="https://nitrosolutions.co.uk" target="_blank" rel="noreferrer">
        Nitro Solutions
      </a>
    </Box>
  );
};

export default Footer;
