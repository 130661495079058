// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
nav > img{
    width: 25%;
}

@media only screen and (max-width: 690px){
    nav > img{
        width: 80%;
    }
}

@media only screen and (min-width: 691px) and (max-width: 850px){
    nav > img{
        width: 50%;
    }

}

@media only screen and (min-width: 851px) and (max-width: 1300px){
    nav > img{
        width: 30%;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ","sourcesContent":["nav{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\nnav > img{\n    width: 25%;\n}\n\n@media only screen and (max-width: 690px){\n    nav > img{\n        width: 80%;\n    }\n}\n\n@media only screen and (min-width: 691px) and (max-width: 850px){\n    nav > img{\n        width: 50%;\n    }\n\n}\n\n@media only screen and (min-width: 851px) and (max-width: 1300px){\n    nav > img{\n        width: 30%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
