import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#77c8cc",
    },
    secondary: {
      main: "#ef7975",
    },
  },
});
