import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {
  TextField,
  Button,
  Checkbox,
  Box,
  FormControlLabel,
  Typography,
  Link,
  Alert,
  AlertTitle,
} from "@mui/material";
import axios from "axios";
import {theme} from "./../theme";


const formStyle = {
  maxWidth: "400px",
  margin: "0 auto",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: 10,
};

function SignUpForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
    trigger,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      surname: "",
      dateOfBirth: "",
      email: "",
      agreeToPrivacyPolicy: false,
    },
  });

  const isGBDateFormat = (dateStr) => {
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    return datePattern.test(dateStr);
  };

  const isOver18 = (dateStr) => {
    const today = new Date();
    const parts = dateStr.split("-");
    const birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
    const ageDiff = today - birthDate;
    const age = Math.floor(ageDiff / (1000 * 60 * 60 * 24 * 365.25));
    return age >= 18;
  };

  const onSubmit = async (data) => {
    try {
      const formData = {
        firstName: data.firstName,
        lastName: data.surname,
        email: data.email,
        dob: data.dateOfBirth,
      };

      const response = await axios.post(
        "https://nitropos-backend.nitrosolutions.co.uk/vapePromotion/submit",
        formData
      );
      setErrorMessage("");
      console.log("Response:", response.data);
      reset();
      setIsSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.response.data);
    }
  };

  return (
    <Box>
      <div>
        <div style={{textAlign:"center",zIndex:"10",color:"#fff",width:"100%",position:"absolute",top:"30vh",height:"45vh", backgroundColor:"rgba(0,0,0,0.7)"}}>
          <h1 style={{marginTop:"35%"}}>THIS DEAL IS NOW OVER.</h1>
          <h2>STAY TUNED FOR THE NEXT ONE!</h2>
        </div>
        <br/>
      <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: "First name is required",
            pattern: {
              value: /^[a-zA-Z ]{2,30}$/,
              message: "Invalid characters in first name",
            },
          }}
          render={({field}) => (
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              onBlur={() => trigger("firstName")}
              autoComplete="given-name"
              disabled={true}
              {...field}
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          rules={{
            required: "Surname is required",
            pattern: {
              value: /^[a-zA-Z ]{2,30}$/,
              message: "Invalid characters in surname",
            },
          }}
          render={({field}) => (
            <TextField
              label="Surname"
              variant="outlined"
              fullWidth
              error={!!errors.surname}
              helperText={errors.surname?.message}
              onBlur={() => trigger("surname")}
              autoComplete="family-name"
              disabled={true}
              {...field}
            />
          )}
        />

        <Controller
          name="dateOfBirth"
          control={control}
          rules={{
            required: "Date of Birth is required",
            validate: {
              validFormat: (value) =>
                isGBDateFormat(value) || "Invalid date format (DD-MM-YYYY)",
              validAge: (value) =>
                isOver18(value) || "You must be at least 18 years old",
            },
          }}
          render={({field}) => (
            <TextField
              label="Date of Birth in DD-MM-YYYY format"
              variant="outlined"
              fullWidth
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth?.message}
              onBlur={() => trigger("dateOfBirth")}
              autoComplete="bday"
              disabled={true}
              {...field}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Invalid email format",
            },
          }}
          render={({field}) => (
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              autoComplete="email"
              disabled={true}
              {...field}
            />
          )}
        />
        <Box>
          <FormControlLabel
            control={
              <Controller
                name="agreeToPrivacyPolicy"
                control={control}
                defaultValue={false}
                rules={{required: "You must agree to the privacy policy"}}
                render={({field}) => <Checkbox {...field} color="primary" />}
              />
            }
            label={
              <Typography color="gray">
                I agree to the{" "}
                <Link
                  href="https://vaped4u.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  Privacy policy
                </Link>
              </Typography>
            }
          />
          {errors.agreeToPrivacyPolicy && (
            <Typography color="error" sx={{fontSize: "0.75rem"}}>
              {errors.agreeToPrivacyPolicy.message}
            </Typography>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="primary"
          disabled={true}
          sx={{color: "white",marginBottom:"5%"}}
        >
          Sign Up
        </Button>
        {errorMessage && (
          <Box sx={{color: theme.palette.error.main}}>{errorMessage}</Box>
        )}
      </form>
      </div>
      {isSuccess && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Please check your email for further instructions.
        </Alert>
      )}
    </Box>
  );
}

export default SignUpForm;
